import React from 'react';
import { PageColor } from '../components/page-color';
import { Content } from '../components/content';
import { Gallery } from '../components/gallery';

export default class JobDetail extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  get job () {
    return this.props.pageContext.job;
  }

  isVideo () {
    return this.job.leftImage.file.url.indexOf('.mp4') > -1;
  }

  render = () => (
    <main className="case">
      <PageColor color="terracotta" nav="pigeon" />
      <div className="case__left">
        <div className="case__left__image">
         { this.job.leftImage?.file?.url && <img src={this.job.leftImage.file.url + '?fm=jpg&w=1200'} /> }
        </div>
      </div>
      <div className="case__right job-overview">
        <div className="case__content">
          <h1>
            <div className="title-poppins">{ this.job.title }</div>
            <div className="title-ivy">{ this.job.subtitle }</div>
          </h1>

          <div className="case__content"><Content document={this.job.content} /></div>
        </div>
      </div>
    </main>
  );
}


//images.ctfassets.net/c4x6a1bvhw39/2zmEzz7fUcEWzvgz67HbNr/6a1ecba1e0b8ae8e8722f3778902bb7c/27576390_599343850400786_1325426486638804992_n.jpg